import styled from '@emotion/styled'
import { Component } from 'react'
import {
  ConfigurationDetail,
  Encodings,
  EncodingValidationRequest,
  EncodingValidationResponse,
  TmrTag,
  TmrKillTag,
} from 'stylewhere/api'
import {
  Box,
  Button,
  EncodingRightHeader,
  IdentifierBox,
  Page,
  ProductInfoBox,
  EncodingReading,
  FullLoadingLayer,
  Spacer,
  IdentifierListModal,
} from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { AppStore, OperationReadingProps, RemoteOperation, RfidReader, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  askUserConfirmation,
  showToast,
  showToastError,
  getEncodingProductFields,
  isRfidAndSerial,
  enabledDisassociateItemTag,
  getAutomaticRestartAntenna,
  isModalError,
  getEncodingTagPassword,
  MAX_KILL_TAG_ATTEMPT,
  sleep,
  getTimeoutValidateTags,
} from 'stylewhere/shared/utils'

interface State {
  encodingValidation: EncodingValidationRequest
  encodingValidationResponse?: EncodingValidationResponse
  antennaOn: boolean
  processing: boolean
  options: {
    value: string
    label: string
    active: boolean
  }[]
  starting: boolean
  originTemplatePath?: string
  targetTags: TmrKillTag[]
  targetTag: string
  visibleModalTags: boolean
  numKillAttempt: number
}

export default class EncodingVerify extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)
  timer: NodeJS.Timeout | null = null
  isModal = false
  isValidate = false

  state: State = {
    encodingValidation: {
      operationId: this.operation.id,
      identifiers: [],
    },
    options: [
      { value: 'associate', label: __(T.misc.associate), active: false },
      { value: 'verify', label: __(T.misc.verify), active: true },
    ],
    antennaOn: false,
    processing: false,
    starting: false,
    targetTags: [],
    targetTag: '',
    visibleModalTags: false,
    numKillAttempt: 0,
  }

  componentDidMount() {
    this.isModal = isModalError(this.operation)
    this.setState({ starting: true, originTemplatePath: this.locationState.originTemplatePath }, this.startReader)
  }

  startReader = async () => {
    try {
      await RfidReader.initialize()
      RfidReader.setOnTagReadCallback(this.onTagRead)
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
      RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    } catch (error) {
      showToastError((error as any).message ?? __(T.error.rfid_reader_initialization), __(T.error.error), this.isModal)
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true, starting: false })
  }

  onStopCallback = () => {
    this.setState({ antennaOn: false, starting: false })
  }

  componentWillUnmount = async () => {
    this.setState({ processing: false })
    await this.stopAntenna()
  }

  removeTagReadFromReader = (tags: TmrTag[]) => {
    const toRemove: string[] = []
    for (let t = 0; t < tags.length; t++) {
      toRemove.push(tags[t].uid ?? tags[t].epc)
    }
    RfidReader.removeTags(toRemove)
  }

  onTagRead = async (tag: TmrTag) => {
    //added a sleep to destroy correctly the timers or wait isValidate flag
    await sleep(Math.random() * 500)

    const { encodingValidation } = this.state
    if (this.isValidate) {
      this.removeTagReadFromReader([tag])
      return
    }
    // if time to validate is 0 then set isEncoding to true immediately for not have validated in parallel
    const timeoutValidateTags = getTimeoutValidateTags(this.operation)
    if (timeoutValidateTags !== undefined && timeoutValidateTags === 0) {
      this.isValidate = true
    }
    try {
      this.stopTimer()
      await EncodingExtensions.onTagRead(encodingValidation, tag, this.operation)
      this.setState({ encodingValidation }, this.onTagReadTimer)
    } catch (error) {
      console.error(error)
    }
  }

  onTagReadTimer = async () => {
    const timeoutValidateTags = getTimeoutValidateTags(this.operation)
    if (timeoutValidateTags !== undefined && timeoutValidateTags > 0) {
      this.timer = setTimeout(async () => {
        this.setState({ processing: true }, this.verifyTag)
      }, timeoutValidateTags)
    } else {
      this.setState({ processing: true }, this.verifyTag)
    }
  }

  sendMixpanelEventData = async (event) => {
    const { encodingValidation } = this.state
    const mixpanelData = EncodingExtensions.getMixPanelData(this.operation, encodingValidation)
    AppStore.sendMixPanelEvent(event, mixpanelData)
  }

  verifyTag = async () => {
    const { encodingValidation } = this.state
    try {
      this.isValidate = true
      this.stopTimer()
      //await this.stopAntenna()
      const encode = await EncodingExtensions.verify(encodingValidation!)
      await this.sendMixpanelEventData('Encoding: Verify Association')
      this.setState(
        {
          encodingValidation: encode.encodingValidation,
          encodingValidationResponse: encode.encodingValidationResponse,
          processing: false,
        },
        this.resetIsValidate
      )
    } catch (error) {
      this.isValidate = false
      this.setState({ processing: false })
      showToastError(
        (error as any).message ?? 'Unknown error during encoding validation',
        __(T.error.error),
        this.isModal
      )
    }
  }

  resetIsValidate = () => {
    this.isValidate = false
  }

  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  clearReads = async (addtags = false) => {
    const { encodingValidation } = this.state
    this.stopTimer()
    if (addtags && encodingValidation && encodingValidation.identifiers) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map(
          (idf) =>
            ({
              uid: idf.identifierType === 'NFC_TAG' && idf.code,
              epc: idf.identifierType === 'UHF_TAG' && idf.code,
              tid: idf.tid || '',
            } as any)
        )
    }
    this.setState(
      {
        encodingValidation: {
          operationId: this.operation.id,
          identifiers: [],
        },
        encodingValidationResponse: undefined,
        targetTags: [],
        targetTag: '',
        numKillAttempt: 0,
        processing: false,
      },
      this.restartAntenna
    )
  }

  restartAntenna = async () => {
    RfidReader.clear()
    this.isValidate = false
    if (!RfidReader.isReading()) {
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    }
  }

  renderIdentifierBox = (_identifier: ConfigurationDetail, index: any) => (
    <IdentifierBox
      widthPerc={(this.state.encodingValidation?.identifiers.length ?? 0) < 3 ? 100 : 50}
      identifier={_identifier}
      key={index}
      onInputSubmit={(barcode) => this.onTagRead({ barcode } as any)}
    />
  )

  canKillTag = () => {
    const { encodingValidationResponse } = this.state
    return (
      AppStore.hasAnyCapabilities('Operation.Encoding.killTag') &&
      this.getUhfTag().length > 0 &&
      encodingValidationResponse &&
      encodingValidationResponse.operationToPerform === 'NONE'
    )
  }

  canDeleteAssociation = () => {
    const { encodingValidationResponse } = this.state
    return (
      AppStore.hasAnyCapabilities('Operation.Encoding.disassociate') &&
      enabledDisassociateItemTag() &&
      encodingValidationResponse &&
      encodingValidationResponse.item &&
      encodingValidationResponse.item.id
    )
  }

  enabledOperaton = () => {
    return this.canKillTag() || this.canDeleteAssociation()
  }

  deleteItem = async () => {
    const { encodingValidationResponse } = this.state
    if (
      encodingValidationResponse?.item &&
      (await askUserConfirmation(__(T.misc.delete), __(T.confirm.confirm_delete_item_association)))
    ) {
      try {
        await this.stopAntenna()
        await Encodings.disassociate({ itemId: encodingValidationResponse.item.id, operationId: this.operation.id })
        await this.sendMixpanelEventData('Encoding: Item Disassociated')
        showToast({ status: 'success', description: __(T.messages.tag_deleted) })
        this.clearReads(true)
      } catch (error) {
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
  }

  getUhfTag = () => {
    const { encodingValidation } = this.state
    const uhfTag: TmrKillTag[] = []
    if (encodingValidation && encodingValidation.identifiers) {
      encodingValidation.identifiers.map((idt) => {
        if (idt.identifierType === 'UHF_TAG') {
          uhfTag.push({
            code: idt.code ?? '',
            tokill:
              idt._errorCode !== undefined &&
              (idt._errorCode.indexOf('UNKNOWN_TAG') !== -1 || idt._errorCode.indexOf('Not Associated Tag') !== -1),
          })
        }
      })
    }
    return uhfTag
  }

  checkKillTag = async () => {
    const { encodingValidation } = this.state
    const tagToKill = encodingValidation.identifiers.filter(
      (i) =>
        i._errorCode !== undefined &&
        (i._errorCode.indexOf('UNKNOWN_TAG') !== -1 || i._errorCode.indexOf('Not Associated Tag') !== -1)
    )
    if (tagToKill.length === 0) {
      showToastError(__(T.error.kill_tag_not_allowed), __(T.error.error), this.isModal)
    } else {
      const uhfTag = this.getUhfTag()
      if (uhfTag.length === 1) {
        if (await askUserConfirmation(__(T.misc.kill_tag), __(T.confirm.confirm_kill_tag, { tag: uhfTag[0].code }))) {
          await this.stopAntenna()
          this.setState({ targetTag: uhfTag[0].code }, this.killTag)
        }
      } else {
        this.setState({ targetTags: uhfTag, visibleModalTags: true })
      }
    }
  }

  confirmIdentifiersModal = async (target: string) => {
    const { numKillAttempt } = this.state
    await this.stopAntenna()
    this.setState({ visibleModalTags: false, targetTag: target, numKillAttempt: numKillAttempt + 1 }, this.killTag)
  }

  closeIdentifiersModal = () => {
    this.setState({ visibleModalTags: false })
  }

  killTag = async () => {
    const { targetTag } = this.state
    try {
      const ret: any = await RfidReader.killTag(targetTag, getEncodingTagPassword(this.operation))
      if (ret.ok) {
        showToast({ status: 'success', description: __(T.messages.tag_killed) })
        this.clearReads()
      } else {
        this.killTagError()
      }
    } catch (error) {
      this.killTagError()
    }
  }

  killTagError = async () => {
    const { numKillAttempt } = this.state
    if (numKillAttempt === MAX_KILL_TAG_ATTEMPT) {
      const ok = await askUserConfirmation(__(T.misc.kill_tag), __(T.misc.retry_kill_tag))
      if (ok) {
        this.setState({ numKillAttempt: 1 }, this.killTag)
      } else {
        showToastError(__(T.error.kill_tag_not_success), __(T.error.error), this.isModal)
        this.clearReads()
      }
    } else {
      this.setState({ numKillAttempt: numKillAttempt + 1 }, this.killTag)
    }
  }

  onTabClick = (tab) => {
    if (tab === 'associate') {
      this.setState({ processing: true }, this._changeTab)
    }
  }

  _changeTab = async () => {
    const { originTemplatePath } = this.state
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(`/encoding/${originTemplatePath ? `${originTemplatePath}/` : ''}:opCode` as any, {
        opCode: this.operation.code,
      })
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    }
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.stop()
    }
  }

  render() {
    const {
      encodingValidation,
      encodingValidationResponse,
      antennaOn,
      processing,
      starting,
      options,
      targetTags,
      visibleModalTags,
    } = this.state
    const identifiers = encodingValidation?.identifiers || []
    const hasRfidAndSerial = isRfidAndSerial(this.operation)
    return (
      <Page
        headerRight={
          <>
            <EncodingRightHeader
              onTabClick={this.onTabClick}
              antennaOn={antennaOn}
              operation={this.operation}
              options={options}
            />
            {AppStore.emulation && <WorkstationEmulationBox>WORKSTATION EMULATION MODE</WorkstationEmulationBox>}
          </>
        }
        title={this.operation.description}
        enableEmulation
      >
        {identifiers.length > 0 && (
          <Page.Sidebar width={500}>
            <Box flex justify={'space-between'}>
              {!!encodingValidationResponse?.item?.product && (
                <ProductInfoBox
                  paddingCard={'20px 20px 20px 20px'}
                  product={encodingValidationResponse.item.product}
                  fields={getEncodingProductFields()}
                />
              )}
              {!encodingValidationResponse?.item?.product && <Box flex />}
              {identifiers.length > 0 && (
                <Button variant="default" title={__(T.misc.clear)} onClick={() => this.clearReads(true)} />
              )}
            </Box>
          </Page.Sidebar>
        )}
        <Page.Content notBoxed>
          {identifiers.length > 0 && (
            <>
              <Box flex row justify={'space-between'} style={{ flexWrap: 'wrap', paddingBottom: 2 }}>
                {identifiers.map(this.renderIdentifierBox)}
              </Box>
              {this.enabledOperaton() && (
                <Box row>
                  {this.canKillTag() && (
                    <Button flex variant="secondary" title={__(T.misc.kill_tag)} onClick={this.checkKillTag} />
                  )}
                  <Spacer />
                  {this.canDeleteAssociation() && <Button flex title={__(T.misc.delete)} onClick={this.deleteItem} />}
                </Box>
              )}
            </>
          )}
          {identifiers.length === 0 && (
            <>
              <EncodingReading
                starting={starting}
                startAntenna={this.startAntenna}
                antennaOn={antennaOn}
                message={
                  antennaOn ? __(T.messages.bring_an_item_to_antenna) : __(T.messages.connection_to_station_in_progress)
                }
                identifier={
                  hasRfidAndSerial
                    ? {
                        identifierType: 'SIMPLE_ITEM_IDENTIFIER',
                        _status: 'TO_BE_READ',
                      }
                    : undefined
                }
                onInputSubmit={hasRfidAndSerial ? (barcode) => this.onTagRead({ barcode } as any) : undefined}
              />
            </>
          )}
        </Page.Content>
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
        <IdentifierListModal
          visible={visibleModalTags}
          tags={targetTags}
          onClose={this.closeIdentifiersModal}
          onConfirm={this.confirmIdentifiersModal}
        />
      </Page>
    )
  }
}

const WorkstationEmulationBox = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 2px;
  border-radius: 0px 0px 0px 8px;
  background-color: lightBlue;
`
